
    export default {
        data() {
            return {
                step : 0,
                maxtimer : 6000,
                currentTimer : 0,
                startTimer : 0,
                timerPerc : 0,
                hasAnswered : false,
                focusAnwer : -1,
                points : 0,
                animateHolder : null,
            }
        },
        props : ['blok'],
        beforeDestroy() {
            if(this.animateHolder) cancelAnimationFrame(this.animateHolder);
            if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
        },
        methods: {
            start(){
                this.step = 1;
                this.points = 0;
                this.timer();
            },
            timer(){
                this.maxtimer = this.blok.questionTimerMax ? parseInt(this.blok.questionTimerMax) * 1000 : this.maxtimer;
                if(!this.hasAnswered){
                    this.startTimer = this.startTimer == 0 ? +new Date() : this.startTimer;
                    this.currentTimer = +new Date();
                    this.timerPerc = parseInt((1-((this.currentTimer-this.startTimer)/this.maxtimer))*100);
                }
                if((this.currentTimer-this.startTimer)>this.maxtimer){
                    this.step ++;
                    this.startTimer = 0;
                }
                if(this.step <= this.blok.questions.length){
                    this.animateHolder = requestAnimationFrame(() => {this.timer()});
                }
            },
            doAnswer(a,i){
                this.hasAnswered = true;
                this.focusAnwer = i;
                this.points += a.isCorrect ? 1 : 0;
                this.timeoutHolder = setTimeout(() => {
                    this.hasAnswered = false;
                    this.focusAnwer = -1;
                    this.startTimer = 0;
                    this.step++;
                    this.timeoutHolder = null;
                }, 2000);
            }
        },
    }
